@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);

blockquote {
  font-size: 1.4em;
  width: 60%;
  margin: 50px auto;
  font-family: Open Sans;
  font-style: italic;
  color: #555555;
  padding: 1.2em 30px 1.2em 75px;
  border-left: 8px solid #78C0A8;
  line-height: 1.6;
  position: relative;
  background: #EDEDED;
}

/*  */

blockquote::before {
  font-family: Arial;
  content: "\201C";
  color: #78C0A8;
  font-size: 4em;
  position: absolute;
  left: 10px;
  top: -10px;
}

blockquote::after {
  content: '';
}

blockquote span {
  display: block;
  color: #333333;
  font-style: normal;
  font-weight: bold;
  margin-top: 1em;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.highlight-high {
  color: red;
  font-weight: bold;
}

.ant-btn-primary {
  background-color: orangered !important;
  color: white !important;
}

input {
  background-color: rgba(251, 192, 147, 0.5);
  border-bottom: 2px solid rgb(255, 152, 0);
}

.ant-table-filter-trigger {
  color: orange !important;
}

.ant-btn {
  color: orange;
  border-color: orange !important;
  /* Set the border color to orange */
}

.ant-btn:hover {
  border-color: orange;
  color: orange !important;
  /* Change the border color to orange when hovering */
}

.ant-checkbox-input {
  color: orange !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: orange !important;
  border-color: orange !important;
}

.ant-checkbox-checked .ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: orange !important;
  background-color: orange !important;
}

.ant-checkbox:hover .ant-checkbox-inner {
  border-color: orange !important;
}

.ant-input:focus {
  border-color: orange !important;
}

/* Change selected date color */
/* Change selected date color */
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: orange;
  color: white;
  /* Change text color to white */
}

/* Change today cell color */
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
  background-color: orange !important;
  color: white !important;
  border-color: orange !important;
}

/* Change header color */
.ant-picker-header {
  background-color: orange;
}

/* Change border color */
.ant-picker-input :hover {
  border: orange !important;
}

.ant-picker {
  border-color: orange !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: orange !important;
  color: white !important;
  /* Change text color to white */
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: orange;
  color: white;
  /* Change text color to white */
}

/* Custom CSS */
.custom-select {
  /* Set the background color of the dropdown to orange */
  background-color: orange !important;
  /* Set the text color of the dropdown options to white */
  color: white !important;
  /* Add padding and border-radius as needed */
  padding: 0.5rem;
  border-radius: 0.25rem;
}

/* Change background color of options on hover and when selected */
.custom-select option:checked {
  background-color: orange !important;
}

.custom-select option:hover {
  background-color: orange !important;
}

body {
  font-family: Calibri, sans-serif;
}